import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Button, Box } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { Navigate, Link } from "react-router-dom";

import { useStyles } from "./style";
import { setTheme, showErrorRequest } from "../../../tools/Tools";
import kreditkarte from "../../../assets/images/kreditkarte.png";
import credit from "../../../assets/images/credit-card.png";
import sha from "../../../assets/images/sha.png";
import Api from "../../../api/Api";
import DialogError from "../cpg/dialogs/DialogError";


let headerIs = "روش واریز خود را انتخاب کنید";
let notGetWay = `در حال حاضر هیچ روش فعالی جهت واریز وجود ندارد . لطفا از روش های دیگر سایت جهت شارژ حساب استفاده نمایید`;
const MsgAddCardPage = ({ setLogo, setNavigate }) => {
  const classes = useStyles();
  const [websiteInfo, setWebsiteInfo] = useState({});
  const [isResult, setIsResult] = useState({
    result: false,
    msg: "شماره کارت وارد نشده است",
    isSuccess: false,
  });



  React.useEffect(() => {



    if (!window.sessionStorage.getItem("isSelectMethod"))
      setNavigate("loading")
    document.title = "انتخاب روش واریز";

    setWebsiteInfo(JSON.parse(window.sessionStorage.getItem("webSiteInfo")))

    setTheme(setLogo);
  }, []);

  const handleCloseDialog = () => {
    setIsResult({ ...isResult, result: false });
  };

  const handleC2C = () => {
    requestPaymentMethod("C2C");
  };

  const handleCPG = () => {
    requestPaymentMethod("CPG");
  };

  const handleIPG = () => {
    requestPaymentMethod("IPG");
  };

  const requestPaymentMethod = async (method) => {
    try {
      window.sessionStorage.setItem("method", method);
      const { status, data } = await Api.setPaymentMethod(method);



      if (status === 200) {
        if (data.success) {
          window.sessionStorage.setItem("helpMessage", data.helpMessage);
          window.sessionStorage.setItem("isDefaultAmountExists", data.isDefaultAmountExists);
          window.sessionStorage.setItem("showDisableVPNBox", data.showDisableVPNBox);
          window.sessionStorage.setItem("disableVPNBox_Message", data.disableVPNBox_Message);


          if (data.userCards.length < 1) {
            if (!data.isUserAllowedToOperateByUndefinedCard && !data.isPendingCardExists) {

              if (data.isUserAllowedToAddCard) {
                window.sessionStorage.setItem("addCard", true);
                window.sessionStorage.setItem("btn_back_addCard", true);

                window.sessionStorage.setItem("isSelectMethodAddCard", true);

                setNavigate("addCard");
              } else {
                window.sessionStorage.setItem("isSelectMethodAddCard", true);
                window.sessionStorage.setItem("PayNot", true);
                window.sessionStorage.setItem(
                  "undefinedCardPaymentErrorMessage",
                  data.undefinedCardPaymentErrorMessage
                );
                setNavigate("PayNotPage", true);
              }

            } else {
              if (data.isUserAllowedToAddCard) {
                if (data.isPendingCardExists) {
                  window.sessionStorage.setItem("isSelectMethodAddCard", true);
                  window.sessionStorage.setItem("PayNot", true);
                  window.sessionStorage.setItem(
                    "undefinedCardPaymentErrorMessage",
                    data.pendingCardErrorMessage
                  );
                  setNavigate("PayNotPage");
                } else {
                  window.sessionStorage.setItem("isSelectMethodAddCard", true);
                  window.sessionStorage.setItem("addCard", true);
                  window.sessionStorage.setItem("btn_back_addCard", true);
                  window.sessionStorage.setItem("addCard_StepStep", true);
                  setNavigate("addCard");
                }
              } else {

                window.sessionStorage.setItem("isSelectMethodAddCard", true);
                window.sessionStorage.setItem("PayNot", true);
                window.sessionStorage.setItem(
                  "undefinedCardPaymentErrorMessage",
                  data.undefinedCardPaymentErrorMessage
                    ? data.undefinedCardPaymentErrorMessage
                    : "شما هیچ کارت تایید شده ای ندارید"
                );
                setNavigate("PayNotPage");
              }
            }
          } else {




            window.sessionStorage.setItem(
              "isPendingCardExists",
              data.isPendingCardExists
            );
            window.sessionStorage.setItem(
              "pendingCardErrorMessage",
              data.pendingCardErrorMessage
            );
            window.sessionStorage.setItem(
              "isPriceListExists",
              data.isPriceListExists
            );

            window.sessionStorage.setItem(
              "priceList",
              JSON.stringify(data.priceList)
            );
            window.sessionStorage.setItem(
              "userCards",
              JSON.stringify(data.userCards)
            );
            window.sessionStorage.setItem(
              "isUserAllowedToPayByUndefinedCard",
              data.isUserAllowedToPayByUndefinedCard
            );
            window.sessionStorage.setItem(
              "isDefaultAmountExists",
              data.isDefaultAmountExists
            );
            window.sessionStorage.setItem("defaultAmount", data.defaultAmount);
            window.sessionStorage.setItem(
              "isUserAllowedToAddCard",
              data.isUserAllowedToAddCard
            );
            window.sessionStorage.setItem(
              "minimumDepositAmount",
              data.minimumDepositAmount
            );
            window.sessionStorage.setItem(
              "maximumDepositAmount",
              data.maximumDepositAmount
            );
            if (method === "CPG") {
              window.sessionStorage.setItem(
                "isNationalCodeRequired",
                data.isNationalCodeRequired
              );
              window.sessionStorage.setItem("showNote", data.showNote);
              window.sessionStorage.setItem("noteMessage", data.noteMessage);
              window.sessionStorage.setItem("isHome", true);
              setNavigate("home");
            } else if (method === "IPG") {

              window.sessionStorage.setItem("ishomeIPG", true);

              setNavigate("homeIPG");
            }
            else {
              window.sessionStorage.setItem("isHomeC2C", true);
              setNavigate("homeC2C");
            }
          }
        } else
          setIsResult({
            ...isResult,
            result: true,
            msg: data.message,
          });
      } else
        setIsResult({
          ...isResult,
          result: true,
          msg: showErrorRequest(status),
        });
    } catch (error) {
      setIsResult({ ...isResult, result: true, msg: showErrorRequest(0) });
    }
  };

  return (

    <Box style={{ marginTop: "40px" }}>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={0}
        className={classes.boxMain}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          xs={12}
          style={{ marginTop: 5, marginLeft: 5 }}
        >
          <Button
            size="small"
            type="submit"
            variant="text"
            color="primary"
            onClick={() => {
              // setIsNextStep(true);
            }}
            className={classes.backBtn}
            startIcon={<ArrowBackIosIcon />}
          >
            <a
              href={
                JSON.parse(window.sessionStorage.getItem("webSiteInfo"))
                  .websiteAddress
              }
              style={{ color: "rgb(57, 57, 57)", textDecoration: "none", direction: "rtl" }}
              target="_self"
            >
              {" "}
              بازگشت به{" "}
              {
                JSON.parse(window.sessionStorage.getItem("webSiteInfo"))
                  .websiteName
              }{" "}
            </a>
          </Button>
        </Grid>


        {(window.sessionStorage.getItem("messageTitle")) != 'undefined' && <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            marginTop: "23px",
            fontFamily: "IRANSans",
          }}
        >
          {window.sessionStorage.getItem("messageTitle")}
        </Grid>}
        <Grid
          item
          xs={11}
          // className={
          //   JSON.parse(window.sessionStorage.getItem("C2C")) ||
          //     JSON.parse(window.sessionStorage.getItem("CPG"))
          //     ? "select_title un-selected"
          //     : "select_title2"
          // }

          className={
            "select_title3 un-selected"
          }
          style={{
            marginTop: "30px",
            marginBottom: 15,
            whiteSpace: "pre-wrap",
            direction: "rtl",
            textAlign: "center",
          }}
        >
          {JSON.parse(window.sessionStorage.getItem("C2C")) || JSON.parse(window.sessionStorage.getItem("IPG")) ||
            JSON.parse(window.sessionStorage.getItem("CPG"))
            ? `👇   ${headerIs}   👇`
            : notGetWay.replace(/\./g, ".\n")}

        </Grid>
        {
          JSON.parse(sessionStorage.getItem("availablePaymentMethods")) && JSON.parse(sessionStorage.getItem("availablePaymentMethods")).map((value, index) => value === "IPG" ?
            <Grid
              item
              xs={10}
              style={{ marginBottom: "30px" }}
            >
              <Button
                size="small"
                type="submit"
                variant="outlined"
                fullWidth
                className={classes.btn3}
                color="primary"
                onClick={handleIPG}
              >
                {websiteInfo?.IPG_selectionText}

                <img src={sha} className="select_ico_ipg" />
                {websiteInfo?.IPG_showSelectionIcon &&
                  <span
                    className="name_select_getway fontDisable name_select_getway_color_green"

                  >IPG</span>}
              </Button>
            </Grid> : value === "C2C" ?
              <Grid
                item
                xs={10}
                style={{ marginBottom: "30px" }}
              >
                <Button
                  size="small"
                  type="submit"
                  variant="outlined"
                  fullWidth
                  className={classes.btn2}
                  color="primary"
                  onClick={handleC2C}
                >
                  {websiteInfo?.C2C_selectionText}

                  <img src={kreditkarte} className="select_ico_c2c" />
                  {websiteInfo?.C2C_showSelectionIcon && <span className="name_select_getway fontDisable">C2C</span>}
                </Button>
              </Grid> :
              <Grid
                item
                xs={10}
                style={{ marginBottom: "30px" }}
              >
                <Button
                  size="small"
                  type="submit"
                  variant="outlined"
                  fullWidth
                  className={classes.btn}
                  color="primary"
                  onClick={handleCPG}
                >
                  {websiteInfo?.CPG_selectionText}

                  <img src={credit} className="select_ico_c2c" />
                  {websiteInfo?.CPG_showSelectionIcon &&
                    <span
                      className="name_select_getway fontDisable name_select_getway_color"
                      style={{ color: "#8E24AA !important" }}
                    >CPG</span>}
                </Button>
              </Grid>

          )
        }


      </Grid>

      <DialogError
        isResult={isResult}
        handleCloseDialog={handleCloseDialog}
        setNavigate={setNavigate}
      />
    </Box >

  );
};
export default MsgAddCardPage;
