import React from 'react';
import Help from "./views/containers/cpg/help"
import TheLayout from "./views/containers/cpg/homePage/HomePage"
import HomePageC2C from "./views/containers/c2c/homePage/HomePage"
import HomePageIPG from "./views/containers/ipg/homePage/HomePage"
import LastStepPageIPG from "./views/containers/ipg/lastStepPage/LastStepPage"
import PaymentSuccessIPG from "./views/containers/ipg/payment_success/PaymentSuccess"
import LastStepPageC2C from "./views/containers/c2c/lastStepPage/LastStepPage"

import SuccessC2C from "./views/containers/c2c/payment_success/PaymentSuccess"
import AddCard from "./views/containers/cpg/addCardPage/AddCard"
import NotActive from "./views/containers/cpg/NotActive"
import LastStepPage from "./views/containers/cpg/lastStepPage/LastStepPage"
import PayNotPage from "./views/containers/cpg/payNotPage/PayNotPage"
import MsgAddCardPage from "./views/containers/cpg/msgAddCardPage/MsgAddCardPage"
import PhonePage from "./views/containers/cpg/phonePage/PhonePage"
import Message from "./views/containers/cpg/addCardPage/MessageAddCard"
import UpdatePage from "./views/containers/cpg/updatePage/UpdatePage"
import FieladPage from "./views/containers/cpg/tokenFieladPage/TokenFieladPage"
import PaymentSueccess from "./views/containers/cpg/payment_success/PaymentSuccess"
import SelectMethodPage from "./views/containers/selectMethod/SelectMethodPage"
import NationalCodePage from "./views/containers/cpg/nationalCode/NationalCodePage"

const routes = [
  { path: '/SuccessC2C', component: SuccessC2C },
  { path: '/last-step-c2c', component: LastStepPageC2C },
  { path: '/homeC2C', component: HomePageC2C },
  { path: '/homeIPG', component: HomePageIPG },
  { path: '/update', component: UpdatePage },
  { path: '/MsgAddCardPage', component: MsgAddCardPage },
  { path: '/selectMethod', component: SelectMethodPage },
  { path: '/last-step-page', component: LastStepPage },
  { path: '/home', component: TheLayout },
  { path: '/help', component: Help },
  { path: '/paymentSuccess', component: PaymentSueccess },
  { path: '/addCard', component: AddCard },
  { path: '/not-active', component: NotActive },
  { path: '/fielad', component: FieladPage },
  { path: '/PayNotPage', component: PayNotPage },
  { path: '/NationalCodePage', component: NationalCodePage },
  { path: '/setPhone', component: PhonePage },
  { path: '/lastStepIPG', component: LastStepPageIPG },
  { path: '/PaymentSuccessIPG', component: PaymentSuccessIPG },

];

export default routes;
