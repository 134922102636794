import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Dialog } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Cancel';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="down"
      ref={ref}
      {...props}
      timeout={{ enter: 450, exit: 450 }}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  dialogLayout: {
    "& div>div": {
      overflowY: "visible !important",
    },
    // "& div>.MuiDialog-paperWidthSm": {
    //   //    minWidth: "400px !important",
    // },
    background: "#0009",
  },
  circleIcon: {

    borderRadius: "50%",
    width: "80px",
    height: "80px",
    // border:"3px solid",
    position: "absolute",
    zIndex: "150",
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    border: "5px solid #ffffff",
    background: "#4CAF50",
    left: "50%",
    transform: "translate(-50%,-50%)",


  },
  successDialog: {
    borderLeft: "5px solid #4CAF50",
    marginTop: "10px",
    background: "#4CAF5040",
    padding: "4px",
    textAlign: "left",
    paddingLeft: "5px",
    fontSize: "14px",
    marginTop: "10px",
    borderRadius: "8px",
  },
  iconDialog: {
    fontSize: "40px",
    color: "white",
  },
  titleDialog: {
    fontSize: "16px",
    color: "#3b3b3b",
    marginTop: "15px",
    textAlign: "center",
  },
  textDialog: {
    textAlign: "center",
    marginTop: "10px",
    width: "100%",
    color: "#757575",
    marginLeft: "15px",
    marginRight: "15px",
    maxWidth: "300px",
    paddingRight: "15px",
    paddingLeft: "15px",
    alignContent: "center",
    alignItems: "center",
    justify: "center",
    justifyContent: "center",
    justifySelf: "center",
    fontSize: "12px",
    marginBottom: "15px",
  },
  layoutButtonDialog: {
    marginTop: "20px",
    color: "#4d4d4d",
    minWidth: "250px",
  },
  image2: {
    height: "70px",
    width: "270px",
    borderRadius: "8px !important",
    marginTop: "5px",
    marginBottom: "5px",
  },
}));
const DialogCode = (props) => {
  const classes = useStyles();
  const {
    isResult,
    handleCloseDialog,
    handleBackDialog,
    handleSubmitDialogCode,
    confirmCodeImageId,
  } = props;
  const [input, setInput] = React.useState();

  const handleChangeInput = (e) => {
    setInput(e.target.value);
  };
  return (
    <Dialog
      open={isResult}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xs"
      onClose={handleCloseDialog}
      className={classes.dialogLayout}
    >

      <div style={{ height: "60px", justifyContent: "center", alignItems: "center", position: "relative" }}>



        <Grid
          container
          className={classes.circleIcon}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <MobileFriendlyIcon className={classes.iconDialog} />


        </Grid>
        <IconButton aria-label="CloseIcon" onClick={handleBackDialog} style={{ position: "absolute", right: 1 }}>
          <CloseIcon style={{ fontSize: 34 }} />
        </IconButton>

      </div>




      <Grid
        container
        direction="column"
        justify="center"
        alignContent="center"
        justifyContent="center"
        className="un-selected"
        style={{ color: "dark", minWidth: "250px" }}
      >
        <Grid item className={classes.titleDialog}>
          {" "}
          درخواست کد تائید
        </Grid>
        <Grid container justify="center" alignContent="center" direction="row">
          {" "}
          <Grid item xs={12} style={{ justifyContent: "center", alignItems: "center", flexDirection: "row", display: "flex" }}>
            <img
              item
              className={classes.image2}
              src={`./confirmCodeImage/${confirmCodeImageId}.jpg`}
            ></img>
          </Grid>
          <Grid item className={`${classes.textDialog}  un-selected`}>
            کد تائید که برای شما ارسال شده است را وارد کنید
          </Grid>
        </Grid>


      </Grid>
      <Grid
        item
        xs={12}
        className="layoutInput"
        style={{ marginRight: 15, marginLeft: 15, marginBottom: 15 }}
      >
        <input
          className="inputCard"
          type="tel"
          pattern="[0-9]*"
          onInput={handleChangeInput}
          value={input}
          maxLength={9}
          placeholder={"کد تائید"}
          style={{ fontSize: "12px" }}
        />
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
        className={classes.layoutButtonDialog}
      >
        <Button
          size="large"
          type="submit"
          variant="contained"
          fullWidth
          color="secondary"
          onClick={() => handleSubmitDialogCode(input)}
          style={{ margin: 5 }}
        >
          ثبت
        </Button>

      </Grid>
    </Dialog>
  );
};
DialogCode.propTypes = {
  isResult: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  codeConfirm: PropTypes.func.isRequired,
};
export default DialogCode;
